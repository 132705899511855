* {
  margin: 0;
  padding: 0;
}

.fdcc {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.grey-input {
  height: 44px;
  width: 44px;
  margin: 10px;
  text-align: center;
  background: rgb(60, 60, 60);
  border: none;
  outline: none;
  color: white;
  font-weight: 600;
  font-size: 24px;
  text-transform: uppercase;
  border-radius: 0px
}

.yellow-input {
  height: 44px;
  width: 44px;
  margin: 10px;
  text-align: center;
  background: rgb(255, 200, 0);
  border: none;
  outline: none;
  color: white;
  font-weight: 600;
  font-size: 24px;
  text-transform: uppercase;
    border-radius: 0px
}

.green-input {
  height: 44px;
  width: 44px;
  margin: 10px;
  text-align: center;
  background: rgb(0, 170, 0);
  border: none;
  outline: none;
  color: white;
  font-weight: 600;
  font-size: 24px;
  text-transform: uppercase;
    border-radius: 0px
}

.name-letter {
  margin: 2px;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid grey;
  font-size: 14px
}

.container {
  display: flex;
  align-items: center;
  padding-top: 20px;
  flex-direction: column;
  justify-content: space-between;
}

.suggTitle {
  display: flex;
  justify-content: center;
  margin-top: 5px;
  margin-bottom: 10px
}

.word {
margin-left: 10px;
margin-right: 10px;
margin-bottom: 5px;
}
